import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      params: {},
    };
  },
  created() {
    console.log(this.propsObj);
    this.params = { terminalCode: this.propsObj.terminalCode, productCode: this.propsObj.productCode };
    this.getConfigList('terminal_tag_log_list');
  },

  methods: {

  },
};
