import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  name: 'terminal_label',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('terminal_tag_list');
  },

  methods: {
    modalClick({ val, row }) {
      console.log('fuck');
      console.log(row);
      console.log(val);
      if (val.code === 'ter_log') {
        this.modalConfig.title = '日志';
        this.propsObjInData = row;
        this.openFull();
      }
    },
  },
};
